import * as z from "zod";
import { CollectionType } from "@prisma/client";

export const CollectionModel = z.object({
  address: z.string(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  minterAddress: z.string(),
  imageUrl: z.string(),
  imageBannerUrl: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  featured: z.boolean(),
  verified: z.boolean().nullish(),
  collectionType: z.nativeEnum(CollectionType),
  domain: z.string().nullish(),
  removedAt: z.coerce.date().nullish(),
  rawDescription: z.string().nullish(),
  imageCompressedUrl: z.string().nullish(),
});
