import * as z from "zod";

export const TicketModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  tokenUid: z.coerce.number().int(),
  used: z.boolean(),
  assignedConfirmed: z.boolean().nullish(),
  assignedToEmail: z.string().nullish(),
  assignedToName: z.string().nullish(),
  censoredDocument: z.string().nullish(),
  hashedDocument: z.string().nullish(),
  shoppingCartItemId: z.coerce.number().int(),
  transferredCount: z.coerce.number().int(),
  transferReason: z.string().nullish(),
  ticketPriceId: z.coerce.number().int(),
});
