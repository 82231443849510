import * as z from "zod";

export const ShoppingCartProductModel = z.object({
  id: z.coerce.number().int(),
  organizerProductId: z.coerce.number().int(),
  shoppingCartId: z.coerce.number().int(),
  quantity: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  quantityClaimed: z.coerce.number().int(),
});
