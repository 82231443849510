import * as z from "zod";
import { seat_icon } from "@prisma/client";

// Helper schema for JSON fields
type Literal = boolean | number | string;
type Json = Literal | { [key: string]: Json } | Json[];
const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const SectionModel = z.object({
  id: z.string(),
  layout: z.array(z.array(z.string())).nullable(),
  name: z.string(),
  description: z.string(),
  maxCapacity: z.coerce.number().int(),
  venueId: z.coerce.number().int(),
  eventId: z.coerce.number().int(),
  priceModifierLayout: z.array(z.array(z.string().or(z.number()))).nullable(),
  icon: z.nativeEnum(seat_icon),
});
