import * as z from "zod";

export const VenueModel = z.object({
  id: z.coerce.number().int(),
  name: z.string(),
  address: z.string(),
  description: z.string(),
  location: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
