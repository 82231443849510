import * as z from "zod";
import { TicketType } from "@prisma/client";

export const TicketPriceModel = z.object({
  id: z.coerce.number().int(),
  name: z.string(),
  price: z.coerce.number().int(),
  priceAsDecimal: z.coerce.number(),
  type: z.nativeEnum(TicketType),
  batchId: z.coerce.number().int(),
});
