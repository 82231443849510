import * as z from "zod";
import { collection_stats_collectionType } from "@prisma/client";

export const CollectionStatsModel = z.object({
  owners: z.bigint(),
  items: z.bigint(),
  address: z.string(),
  name: z.string(),
  slug: z.string(),
  description: z.string(),
  minterAddress: z.string(),
  imageUrl: z.string(),
  imageBannerUrl: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  featured: z.boolean(),
  verified: z.boolean().nullish(),
  collectionType: z.nativeEnum(collection_stats_collectionType),
  domain: z.string().nullish(),
  removedAt: z.coerce.date().nullish(),
  rawDescription: z.string().nullish(),
  imageCompressedUrl: z.string().nullish(),
  volume: z.coerce.number(),
  floorPrice: z.coerce.number(),
});
