import * as z from "zod";

export const BatchStatsModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  eventId: z.coerce.number().int(),
  endAt: z.coerce.date().nullish(),
  startAt: z.coerce.date().nullish(),
  name: z.string(),
  quantity: z.coerce.number().int(),
  sectionId: z.string(),
  studentQuantity: z.bigint(),
  regularQuantity: z.bigint(),
  studentUnavailable: z.coerce.number(),
  regularUnavailable: z.coerce.number(),
  studentSold: z.coerce.number(),
  regularSold: z.coerce.number(),
  isBatchOpen: z.coerce.number().int().nullish(),
});
