import * as z from "zod";
import { OfferType, OfferStatus } from "@prisma/client";

export const OfferModel = z.object({
  id: z.coerce.number().int(),
  price: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  executedAt: z.coerce.date().nullish(),
  expiration: z.coerce.number().int(),
  type: z.nativeEnum(OfferType),
  status: z.nativeEnum(OfferStatus),
  signature: z.string(),
  makerAddress: z.string(),
  priceAsDecimal: z.coerce.number(),
  takerAddress: z.string().nullish(),
  tokenUid: z.coerce.number().int(),
  itemAmount: z.coerce.number().int(),
  itemsSold: z.coerce.number().int(),
});
