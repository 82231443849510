import * as z from "zod";
import { batch_time_series_type } from "@prisma/client";

export const BatchTimeSeriesModel = z.object({
  amountSold: z.coerce.number().nullish(),
  date: z.coerce.date().nullish(),
  dateString: z.string().nullish(),
  type: z.nativeEnum(batch_time_series_type),
  salesTotal: z.coerce.number(),
  name: z.string(),
  id: z.coerce.number().int(),
  eventId: z.coerce.number().int(),
});
