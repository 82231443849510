import * as z from "zod";

export const OrganizerProductModel = z.object({
  id: z.coerce.number().int(),
  name: z.string(),
  description: z.string(),
  imageUrl: z.string(),
  price: z.coerce.number().int(),
  priceAsDecimal: z.coerce.number(),
  quantity: z.coerce.number().int().nullish(),
  eventId: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  organizerId: z.coerce.number().int(),
  hidden: z.boolean(),
  itemValue: z.coerce.number(),
});
