import { type GetAccountResult, type Provider } from "@wagmi/core";
// import {
//   Web3EmailConnector,
//   getWeb3AuthInstance,
//   isWeb3AuthConnector,
// } from "@components/web3/Web3AuthConnector";
import { getTicketingHref } from "@components/TicketingLink";
import { setRedirectUrl } from "@components/web3/web3Auth";
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationOptions,
} from "@tanstack/react-query";
import { applicationChain } from "@utils/applicationChain";
import { isWeb3AuthConnector, parseAddress } from "@utils/index";
import { trpc } from "@utils/trpc";
import { type Web3AuthNoModal } from "@web3auth/no-modal";
import { type userInfoValidator } from "common/validators/userValidators";
import { userProviderValidator } from "common/validators/values";
import { getCsrfToken, signIn, signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { type z } from "zod";
import { useAccount, useConnect, useWagmi } from "./useWagmi";
import { UserModel } from "database/prisma/zod";

const w3a = import("@components/web3/web3Auth");
export const useW3AConnector = (modal = false) => {
  return useQuery({
    queryKey: ["w3a-connector", modal],
    queryFn: async () => {
      const { Web3AuthConnector } = await import(
        "@web3auth/web3auth-wagmi-connector"
      );
      const { getWeb3AuthInstance } = await w3a;
      return new Web3AuthConnector({
        chains: [applicationChain],
        options: {
          web3AuthInstance: getWeb3AuthInstance(modal),
        },
      });
    },
  });
};

export const useGoogleConnector = () => {
  return;
};

export const useConnectWithEmail = () => {
  const session = useSession();
  const { wagmi } = useWagmi();
  const router = useRouter();
  return useMutation({
    mutationKey: ["connect-with-email", session],
    mutationFn: async (email?: string) => {
      if (!wagmi) throw new Error("Wagmi not initialized");
      await wagmi.core.disconnect();
      const { Web3EmailConnector, getWeb3AuthInstance } = await w3a;
      if (router && router.query && typeof router.query.redirect === "string") {
        setRedirectUrl(router.query.redirect);
      }
      localStorage.setItem("wagmi:connector", "email");
      return wagmi.core.connect({
        connector: new Web3EmailConnector({
          chains: [applicationChain],
          options: {
            web3AuthInstance: getWeb3AuthInstance(false),
          },
          email: session.data?.user?.email ?? email ?? "",
        }),
      });
    },
  });
};

export const useReconnectW3A = (modal = false) => {
  const { data: connector } = useW3AConnector(modal);
  const { wagmi } = useWagmi();
  const account = useAccount();
  return useQuery({
    queryKey: ["autoconnect", !!connector, !!wagmi],
    queryFn: async () => {
      if (!connector || !wagmi) return null;
      const web3AuthInstance = connector.web3AuthInstance as Web3AuthNoModal;
      if (web3AuthInstance.status === "not_ready") {
        await web3AuthInstance.init();
      }
      if (web3AuthInstance.status === "connected") {
        await wagmi.core.connect({ connector });
      }
      return web3AuthInstance.status;
    },
    enabled: connector && !!connector.web3AuthInstance && !account.isConnected,
    refetchInterval: 1000,
    initialData: "not_ready",
  });
};
type LoginArguments = GetAccountResult<Provider>;
type UseSiweAuthOptions = Omit<
  UseMutationOptions<void, unknown, LoginArguments, unknown>,
  "mutationFn" | "mutationKey"
>;
export const useSiweAuth = (opts?: UseSiweAuthOptions) => {
  const { wagmi } = useWagmi();
  const { data: session } = useSession();
  const router = useRouter();
  const { mutateAsync: checkExists } = trpc.user.exists.useMutation();
  const handleDisconnect = useCallback(async () => {
    if (!wagmi) return;
    await signOut({
      redirect: false,
    });
    await wagmi.core.disconnect();
    localStorage.clear();
  }, [wagmi]);

  const handleLogin = async (account: LoginArguments) => {
    try {
      const { SiweMessage } = await import("siwe");
      const chainId = applicationChain.id;
      const address = account?.address;
      const connector = account?.connector;

      if (!address || !chainId || !connector || !wagmi) {
        return;
      }
      let userInfo: z.infer<typeof userInfoValidator>;
      // if (connector.id === "injected") {
      //   opts?.events?.start?.();
      // }
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: "Sign in with Ethereum to the app.",
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce: await getCsrfToken(),
      });

      const signature = await wagmi.core.signMessage({
        message: message.prepareMessage(),
      });

      if (isWeb3AuthConnector(connector)) {
        const web3AuthInstance = connector.web3AuthInstance as Web3AuthNoModal;
        userInfo = await web3AuthInstance.getUserInfo();
      } else {
        userInfo = { typeOfLogin: "injected" };
      }
      const parsedLoginMethod = userProviderValidator.parse(
        userInfo.typeOfLogin
      );
      const methodFromLocalHost = localStorage.getItem("wagmi:connector");
      if (parsedLoginMethod !== methodFromLocalHost) {
        throw new Error("Mismatched login method", { cause: "wrongProvider" });
      }
      const userExists = await checkExists({
        id: address,
      });
      if (
        userExists &&
        userExists.userProvider !== "unknown" &&
        parsedLoginMethod !== userExists?.userProvider
      ) {
        throw new Error("Email already registered. Try another login method", {
          cause: "wrongProvider",
        });
      }
      const response = await signIn("credentials", {
        message: JSON.stringify(message),
        redirect: false,
        signature,
        connector: connector?.id,
        userInfo: JSON.stringify(userInfo),
      });
      if (response && (response.error || !response.ok)) {
        void handleDisconnect();
        throw new Error(response.error, { cause: "nextAuth" });
      }

      const redirect = router.query.redirect as string;

      const parsedUser = UserModel.safeParse(userExists);
      const userFlagsArray = Object.values(
        parsedUser.success && parsedUser.data.flags ? parsedUser.data.flags : {}
      );
      const isMissingFlags =
        userFlagsArray.length === 0 || userFlagsArray.some((flag) => !flag);

      const params = new URLSearchParams();
      params.append("redirect", redirect ?? getTicketingHref("/"));
      if (!userExists || isMissingFlags) {
        params.append("profileIncomplete", "true");
        const redirectUrl = new URL(
          window.location.origin + "/profile/update" + "?" + params.toString()
        );
        void router.push(redirectUrl);
      } else if (redirect) {
        void router.push(params.get("redirect")!);
      }
    } catch (error) {
      console.log("Error signing in", error);
      void handleDisconnect();
      // opts?.events?.error?.();
      throw error;
    }
  };
  return useMutation({
    mutationKey: ["siwe-auth", session],
    mutationFn: handleLogin,
    ...opts,
  });
};

export const useReconnectInjected = (modal: boolean) => {
  const session = useSession();
  const { data: w3aStatus } = useReconnectW3A(modal);
  const account = useAccount();
  const connect = useConnect();
  useEffect(() => {
    if (
      session.data?.address &&
      w3aStatus &&
      // If it gets to ready means it initialized but didnt find an account
      w3aStatus === "ready" &&
      account.status === "disconnected" &&
      connect.status === "idle"
    ) {
      connect.mutate({
        connector: "injected",
        reauth: false,
      });
    }
  }, [session, w3aStatus, account, connect]);
};
export const useAuthedAccount = (modal = false) => {
  const session = useSession();
  const { wagmi } = useWagmi();
  const w3a = useReconnectW3A(modal);
  useReconnectInjected(modal);
  const { status, mutate } = useSiweAuth();
  const account = useAccount();
  useEffect(() => {
    if (account.isConnected && !account.isConnecting && status === "idle") {
      mutate(account);
    }
  }, [account, mutate, status]);
  return useQuery({
    queryKey: ["authed-account", modal, session],
    queryFn: () => {
      if (!wagmi) return null;
      const account = wagmi.core.getAccount();
      if (
        session.data?.address &&
        account.address &&
        !w3a.isLoading &&
        parseAddress(session.data?.address) === parseAddress(account.address)
      ) {
        return account;
      }
      return null;
    },
    enabled: !!wagmi,
    initialData: null,
  });
};

export const useRequireEmailValidation = () => {
  const { data: session } = useSession();
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    queryClient.setQueryData(["emailValidationModal"], true);
  }, [queryClient]);

  const router = useRouter();
  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends (...args: any[]) => any>(
      cb: T
    ): ((...args: Parameters<T>) => ReturnType<T> | void) => {
      return (...secondArgs: Parameters<T>) => {
        if (!session) {
          void router.push(
            `/login?redirect=${encodeURIComponent(router.asPath)}`
          );
          return;
        }
        if (!session.isEmailValidated) {
          invalidate();
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cb(...secondArgs);
      };
    },
    [session, router, invalidate]
  );
};
