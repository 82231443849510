import * as z from "zod";
import { ParentalRating } from "@prisma/client";

export const EventModel = z.object({
  id: z.coerce.number().int(),
  collectionAddress: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  startsAt: z.coerce.date(),
  endsAt: z.coerce.date(),
  venueId: z.coerce.number().int(),
  category: z.string(),
  maxPerAccount: z.coerce.number().int().nullish(),
  verifyDocument: z.boolean(),
  verifyStudent: z.boolean(),
  parentalRating: z.nativeEnum(ParentalRating),
  timezone: z.string(),
  parentEventId: z.coerce.number().int().nullish(),
  publishedAt: z.coerce.date().nullish(),
});
