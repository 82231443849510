import * as z from "zod";
import { UserRoles, UserProvider } from "@prisma/client";

// Helper schema for JSON fields
type Literal = boolean | number | string;
type Json = Literal | { [key: string]: Json } | Json[];
const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodSchema<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)])
);

export const UserModel = z.object({
  address: z.string(),
  imageUrl: z.string(),
  imageBannerUrl: z.string(),
  name: z.string().nullish(),
  slug: z.string().nullish(),
  email: z.string().nullish(),
  description: z.string().nullish(),
  twitterHandle: z.string().nullish(),
  instagramHandle: z.string().nullish(),
  externalSite: z.string().nullish(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  verified: z.boolean(),
  emailVerified: z.coerce.date().nullish(),
  role: z.nativeEnum(UserRoles),
  receivedFirstTimeGift: z.boolean().nullish(),
  domain: z.string().nullish(),
  removedAt: z.coerce.date().nullish(),
  flags: z
    .object({
      termsOfService: z.boolean().default(false),
      refundPolicy: z.boolean().default(false),
      privacyPolicy: z.boolean().default(false),
    })
    .nullish(),
  userProvider: z.nativeEnum(UserProvider),
});
