import * as z from "zod";
import { OnboardingStatus } from "@prisma/client";

export const OrganizerModel = z.object({
  id: z.coerce.number().int(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  userAddress: z.string(),
  stripeAccountID: z.string().nullish(),
  address: z.string(),
  taxId: z.string(),
  onboardingStatus: z.nativeEnum(OnboardingStatus),
});
