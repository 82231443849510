import * as z from "zod";
import { ImageFit } from "@prisma/client";

export const TokenModel = z.object({
  uid: z.coerce.number().int(),
  id: z.string(),
  collectionAddress: z.string(),
  imageUrl: z.string(),
  imageCloudinaryUrl: z.string().nullish(),
  title: z.string().nullish(),
  slug: z.string().nullish(),
  description: z.string().nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  minterAddress: z.string(),
  ownerAddress: z.string(),
  featured: z.boolean(),
  itemFit: z.nativeEnum(ImageFit),
  cardFit: z.nativeEnum(ImageFit),
  domain: z.string().nullish(),
  verified: z.boolean().nullish(),
  removedAt: z.coerce.date().nullish(),
  amount: z.coerce.number().int(),
});
